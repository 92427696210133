import React from 'react';
import './App.css';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import CsvConvert from "./components/CsvConvert/CsvConvert";

function App() {
    return (
        <>
            <ToastContainer autoClose={8000} toastClassName="dark-toast"/>
            <div className="App">
                <header className="App-header">
                    <CsvConvert/>
                </header>
            </div>
        </>
    );
}

export default App;
