import React, {useEffect, useState} from "react";
import CsvDropzone from "../../common/components/csv-dropzone";
import {CSVLink} from "react-csv";

const csv = require('fast-csv');

const CsvConvert = () => {
    const [file, setFile] = useState();
    const [filename, setFilename] = useState();
    const [rows, setRows] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [customers, setCustomers] = useState([]);

    const onFilename = (filename) => {
        setFilename(filename);
    };

    const onFile = (file) => {
        setFile(file);
    };

    useEffect(() => {
        if (file) {
            let lines = file.split("\n");
            if (!lines[1].startsWith('"KUNDER'))
                lines = lines.map((line, idx) => idx<3 ? line : idx%2 ? line : line+'"');
            lines[0] = '"'+lines[0]+'"';
            lines[2] = '"'+lines[2]+'"';

            let result = [];
            const stream = csv.parse({headers: false, delimiter: ' ', ignoreEmpty: true})
                .on('error', error => console.error(error))
                .on('data', row => result.push(row))
                .on('end', rowCount => setRows(result));

            stream.write(lines.join("\n"));
            stream.end();
        }
    }, [file]);

    useEffect(() => {
        parseRows();
    }, [rows]);

    const dateToIso = (dateString) => {
        const year = dateString.substr(0, 4);
        const month = dateString.substr(4, 2);
        const day = dateString.substr(6, 2);

        return year + "-" + month + "-" + day;
    }

    const parseRows = () => {
        let type = "FAKT";
        let invoices = {};
        let customers = {};
        let i = 0;

        for (let row of rows) {
            if (i === 1) {
                type = row[0];
            }

            if (i < 3 || (["FAKT", "DF"].indexOf(type) !== -1 && i % 2 === 0)) {
                i++;
                continue;
            }

            switch (type) {
                case "FAKT": // Invoices
                case "DF": // Credit notes etc.
                    const negativeAmount = row[3] === "12"; // 12 = Credit note, 11 = Invoice
                    const invoiceNo = row[0];
                    const invoiceDate = dateToIso(row[1]);
                    const dueDate = dateToIso(row[14]);
                    const kid = row[17];
                    const paymentType = 1;
                    const amount = 0;
                    const orderNo = invoiceNo;
                    const orderDate = dateToIso(row[1]);
                    const invoiceCustomerNo = parseInt(row[16]);
                    const invoiceCustomerName = row[5];
                    const deliveryDate = invoiceDate;
                    const orderLineDescription = negativeAmount ? "Kreditnota utsendt fra Fenix" : "Faktura utsendt fra Fenix";
                    const orderLineUnitPrice = parseFloat(row[12]) * (negativeAmount ? -1 : 1);
                    const orderLineCount = 1;
                    const orderLineVatCode = parseInt(row[8]) || parseInt(row[9]) || 0;

                    if (!(invoiceNo in invoices)) {
                        const newRow = [invoiceNo, invoiceDate, dueDate, kid, paymentType, amount, orderNo, orderDate, invoiceCustomerNo, invoiceCustomerName, deliveryDate, orderLineDescription, orderLineUnitPrice, orderLineCount, orderLineVatCode];
                        invoices[invoiceNo] = [];
                        invoices[invoiceNo].push(newRow);
                    } else {
                        const newRow = ['', '', '', '', '', '', orderNo, '', '', '', '', orderLineDescription, orderLineUnitPrice, orderLineCount, orderLineVatCode];
                        invoices[invoiceNo].push(newRow);
                    }
                    break;
                case 'KUNDER':
                    const customerNumber = row[5];
                    const customerName = row[0];
                    const address1 = row[1];
                    const address2 = row[2];
                    const postalCode = row[3];
                    const postalCity = row[4];

                    if (!(customerNumber in customers)) {
                        const newRow = [customerNumber, '', customerName, '', '', '', '', '', address1, address2, postalCode, postalCity];
                        customers[customerNumber] = [];
                        customers[customerNumber].push(newRow);
                    } else {
                        const newRow = [customerNumber, '', customerName, '', '', '', '', '', address1, address2, postalCode, postalCity];
                        customers[customerNumber].push(newRow);
                    }
            }

            i++;
        }

        let flat;
        switch (type) {
            case "FAKT":
            case "DF":
                flat = [["INVOICE NO","INVOICE DATE","DUE DATE","KID","PAYMENT TYPE","PAID AMOUNT","ORDER NO","ORDER DATE","CUSTOMER NO","CUSTOMER NAME","DELIVERY DATE","ORDER LINE - DESCRIPTION","ORDER LINE - UNIT PRICE","ORDER LINE - COUNT","ORDER LINE - VAT CODE"]];
                for (let invoiceNo of Object.keys(invoices)) {
                    for (let invoice of invoices[invoiceNo]) {
                        flat.push(invoice);
                    }
                }

                setCustomers([]);
                setInvoices(flat);
                break;
            case "KUNDER":
                flat = [["Kundenummer","Leverandørnummer","Navn","Organisasjonsnummer","Telefon","Mobil","Faks","Epost","Postadresse - linje 1","Postadresse - linje 2","Postadresse - postnummer","Postadresse - sted"]];
                for (let customerNumber of Object.keys(customers)) {
                    for (let customer of customers[customerNumber]) {
                        flat.push(customer);
                    }
                }

                setInvoices([]);
                setCustomers(flat);
                break;

        }
    };

    return (
        <>
            <CsvDropzone onFile={onFile} onFilename={onFilename}/>
            <CSVLink
                data={customers.length > 0 ? customers : invoices}
                separator={";"}
                className={"button "+(Math.max(customers.length, invoices.length)===1 ? "disabled" : "")}
                filename={ filename ? filename.replace('.txt', '.csv') : 'tripletex-import.csv' }
            >Last ned CSV</CSVLink>
        </>
    )
}


export default CsvConvert;
