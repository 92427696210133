import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useDropzone} from "react-dropzone";
import {toast} from "react-toastify";

const iconv = require('iconv-lite');

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    height: '300px',
    width: '400px',
    transition: 'border .24s ease-in-out'
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

const CsvDropzone = ({onFile, onFilename}) => {
    const [file, setFile] = useState(null);
    const [filename, setFilename] = useState(null);
    const maxSize = 100000000;

    const onDrop = useCallback(acceptedFiles => {
        if (acceptedFiles.length > 1) {
            return toast.error('Kun 1 fil om gangen');
        }

        setFilename(acceptedFiles[0].name);

        const reader = new FileReader();
        reader.onload = function () {
            let target_data = iconv.decode(Buffer.from(this.result, 'binary'), 'iso-8859-1');
            setFile(target_data);
        }
        reader.readAsBinaryString(acceptedFiles[0])
    }, []);

    const {isDragActive, getRootProps, getInputProps, isDragReject, isDragAccept, acceptedFiles, rejectedFiles} = useDropzone({
        onDrop,
        accept: ['text/csv', 'text/plain'],
        minSize: 0,
        maxSize: maxSize,
    });

    const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject
    ]);

    useEffect(() => {
        onFile(file);
    }, [file]);

    useEffect(() => {
        onFilename(filename);
    }, [filename]);

    return (
        <section className="container">
            <div {...getRootProps({style})}>
                <input {...getInputProps()} />
                {!isDragActive && 'Klikk her eller slipp en csv-fil for å konvertere.'}
                {isDragActive && !isDragReject && "Slipp for å laste opp!"}
                {isDragReject && "Filtypen er ikke akseptert, beklager!"}
                {isFileTooLarge && (
                    <div className="text-danger mt-2">
                        Filen er for stor. Må være under {maxSize}B.
                    </div>
                )}
                <em>(Kun *.csv og *.txt er akseptert)</em>
            </div>
        </section>
    );
}

export default CsvDropzone;
